import moment from "moment";
import Link from "next/link";
import GoogleSignInButton from "components/shared/buttons/GoogleSignInButton";
import SingleJournalPage from "./SingleJournalPage";
import { useCurrentUserContext } from "contexts/CurrentUserContext";

export default function LandingPage({date, usersCount}:{ date: moment.Moment, usersCount: Number}) {
  const { demoUser } = useCurrentUserContext();

  return (
    <div>
      <main>
        {/* Hero section */}
        <div className="md:pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div className="mt-12 md:mt-20">
                <div>
                  <Link href="/demo/day" passHref>
                    <a className="inline-flex space-x-4 group">
                      <span className="group-hover:bg-blue-100 rounded bg-blue-50 px-2.5 py-1 text-xs font-semibold text-blue-500 tracking-wide uppercase">
                        How it works
                      </span>
                      <span className="inline-flex items-center text-sm font-medium text-blue-500 space-x-1 group-hover:underline">
                        <span>See an example</span>
                        <i className="fas fa-angle-right text-black"></i>
                      </span>
                    </a>
                  </Link>
                </div>
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl space-x-4">
                    <span className="relative">
                      <i className="fa-solid fa-head-side-brain absolute"></i>
                      <i className="fa-solid fa-arrow-right absolute text-base top-3 left-3"></i>
                    </span>

                    <span className="pl-12">Line of Thought</span>
                  </h1>
                  <p className="mt-6 text-xl text-gray-500">
                    A minimalist mood and life tracker by{" "}
                    <a className="text-blue-500 hover:underline" href="https://ruby.social/@adam" target="_blank" rel="me">@adam@ruby.social</a>.
                  </p>
                </div>
                <div className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                  <GoogleSignInButton />
                </div>
                <div className="mt-6">
                  <div className="inline-flex items-center divide-x divide-gray-300">
                    <div className="min-w-0 flex-1 py-1 text-sm text-gray-500 sm:py-3">
                      <span className="font-medium text-gray-900">Join over </span>
                      <span className="font-medium text-blue-600">{String(usersCount)} beta users. 😂</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="lg:py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="w-full mt-8 lg:mt-24 lg:mt-0 md:p-0 md:max-w-xl mx-auto mt-2 lg:pb-32 flex-grow flex flex-col items-center place-content-center justify-center h-full space-y-4">
                <SingleJournalPage user={demoUser} date={date} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
