import { useMemo } from "react";
import SingleJournalPage from "components/pages/SingleJournalPage";
import useCurrentUser from "hooks/useCurrentUser";
import LandingPage from "components/pages/LandingPage";
import moment from "moment";
import { getTotalUsers } from "queries/users/getTotalUsers";

export default function Home({usersCount}:{usersCount:Number}) {
  const { currentUser, loading } = useCurrentUser();
  const now = useMemo(() => moment(new Date()), []);
  
  if(loading) {
    return (
      <div className="grid h-screen place-items-center">
        <div className="text-center">
          <p className="text-2xl font-bold mb-1">Line of Thought</p>
          <p className="">Just a sec, we gotta load stuff.</p>
          <p className="mt-8">
            <i className="fas fa-arrows-rotate text-2xl animate-spin" />
          </p>
        </div>
      </div>
    )
  }

  if(currentUser?.id) {
    return <SingleJournalPage date={now} user={currentUser} />
  } else {
    return <LandingPage date={now} usersCount={usersCount} />
  }
}

export async function getStaticProps() {
  const result = await getTotalUsers();

  return {
    props: {
      usersCount: result.data.users_aggregate.aggregate.count
    },
    revalidate: 60 * 60, // In seconds
  };
}
